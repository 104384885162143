import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('slideShow', () => ({
    slideIndex: 0,
    player: null,
    slides: [],
    players: {},
    delay: 20000,
    mute: false,
    init() {
        // Grab all player divs and build a mapping.
        const playerDivs = Array.from(document.getElementsByClassName('player'));
        this.wait(() => {
            playerDivs.forEach((p) => {
                let pid = p.getAttribute('data-player-id');
                const player = window.frop_players[pid];
                if (player) {
                    this.players[pid] = player;
                }
            });
        });
        this.slides = Array.from(document.getElementsByClassName('slide'));
        if (this.slides.length) {
            // Start auto advancing slides.
            this.next();
        }
    },
    next() {


        // Select the current slide.
        const currentSlide = this.slides[this.slideIndex];
        // Look for a video element inside the current slide.
        const videoElement = currentSlide.querySelector('video');
        const playerElement = videoElement ? videoElement.closest('.player') : null;
        const id = playerElement ? playerElement.getAttribute('data-player-id') : null;

        const player = id ? window.frop_players[id] : null;

        this.player = player

        if (this.player) {
            this.player.setMute(this.mute);
            this.player.seek(0);

            // Use a one-time event listener so we don't accumulate multiple callbacks.
            if (typeof this.player.once === 'function') {
                this.player.once('complete', () => {
                    this.advanceSlide();
                    this.next();
                });
            } else {
                // Fallback if .once() isn't available.
                const handler = () => {
                    this.advanceSlide();
                    this.next();
                };
                // Optionally clear previous event listeners if your API supports it.
                // this.player.off('complete');
                this.player.on('complete', handler);
            }
        } else {
            // No player on the current slide—advance the slide after the set delay.
            setTimeout(() => {
                this.advanceSlide();
                this.next();
            }, this.delay);
        }
    },
    wait(callback) {
        if (window.frop_players !== undefined) {
            callback();
        } else {
            setTimeout(() => this.wait(callback), 500); // Check again in 500ms.
        }
    },
    advanceSlide() {
        // Advance only one slide at a time.
        this.slideIndex = (this.slideIndex + 1) % this.slides.length;
    },

}));
